import { render, staticRenderFns } from "./tab.vue?vue&type=template&id=1ff78c70&scoped=true&"
import script from "./tab.vue?vue&type=script&lang=js&"
export * from "./tab.vue?vue&type=script&lang=js&"
import style0 from "./tab.vue?vue&type=style&index=0&id=1ff78c70&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff78c70",
  null
  
)

export default component.exports